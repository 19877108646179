@import '~ethos-design-system/src/components/Media/Media.scss';

.cards {
  margin: var(--Space-48) 0 var(--Space-32);

  @include for-tablet-only {
    margin: var(--Space-48) 0 var(--Space-48);
  }

  @include for-laptop-only {
    margin: var(--Space-64) 0 var(--Space-64);
  }

  @include for-desktop-only {
    margin: var(--Space-80) 0 var(--Space-80);
  }
}

.headings {
  margin-bottom: var(--Space-48);

  @include for-tablet-only {
    max-width: 480px;
  }

  @include for-laptop-and-up {
    max-width: 640px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include for-phone-only {
    flex-direction: column;
  }

  .thirds,
  .fourths {
    display: flex;
  }

  .thirds {
    @include for-tablet-only {
      width: calc(33.33% - var(--Space-12));
    }

    @include for-laptop-only {
      width: calc(33.33% - var(--Space-16));
    }

    @include for-desktop-only {
      width: calc(33.33% - var(--Space-24));
    }

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      @include for-tablet-only {
        margin-top: var(--Space-16);
      }

      @include for-laptop-only {
        margin-top: var(--Space-24);
      }

      @include for-desktop-only {
        margin-top: var(--Space-40);
      }
    }
  }

  .fourths {
    @include for-tablet-only {
      width: calc(50% - var(--Space-8));
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: var(--Space-16);
      }
    }

    @include for-laptop-only {
      width: calc(25% - var(--Space-12));
    }

    @include for-desktop-only {
      width: calc(25% - var(--Space-16));
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include for-phone-only {
    margin-bottom: var(--Space-24);
  }
}

.border {
  &.flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  width: 100%;
  height: 100%;
  border: solid 1px var(--GrayStrokeAndDisabled--translucent);
  padding: var(--Space-16) var(--Space-24) var(--Space-24);

  &.large {
    border-top: none;
  }

  &.small {
    padding-top: var(--Space-24);
  }
}

.image {
  &.small {
    width: 56px;
    height: 56px;
    margin-bottom: var(--Space-16);
  }

  &.large {
    width: 100%;
  }
}

.cardCopy {
  &.large,
  &.none {
    padding-top: var(--Space-8);
  }
}

.bodyCopy {
  color: var(--GrayDarkHover--opaque);
  padding-top: var(--Space-16);
}

.cta {
  margin-top: var(--Space-32);
  border-top: solid 1px var(--GrayStrokeAndDisabled--translucent);
  padding-top: var(--Space-16);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
